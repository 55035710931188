<script setup></script>

<template>
    <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 491.6 117.4"
        style="enable-background: new 0 0 491.6 117.4"
        xml:space="preserve"
    >
        <g>
            <g>
                <path
                    class="st0"
                    d="M466.8,97.7h22.5v-5.1h-16.8V65.7h-5.6V97.7z M439.9,85l5.5-12.8l5.5,12.8H439.9z M428.8,97.7h5.8l3.3-7.7
			H453l3.2,7.7h5.9l-14.1-32.2h-5.2L428.8,97.7z M396.2,97.7h5.5V75l17.7,22.8h4.7v-32h-5.5v22.1l-17.1-22.1h-5.2V97.7z M373.2,93.1
			c-6.4,0-10.9-5.1-10.9-11.3v-0.1c0-6.2,4.4-11.3,10.8-11.3c6.4,0,10.9,5.2,10.9,11.4v0.1C384,88,379.6,93.1,373.2,93.1
			 M373.1,98.3c9.8,0,16.8-7.5,16.8-16.6v-0.1c0-9-6.9-16.5-16.7-16.5c-9.8,0-16.8,7.5-16.8,16.6v0.1
			C356.4,90.8,363.3,98.3,373.1,98.3 M344.4,97.7h5.6v-32h-5.6V97.7z M323.6,97.7h5.7V70.9h10.2v-5.2h-26v5.2h10.2V97.7z M293,85
			l5.5-12.8L304,85H293z M281.9,97.7h5.8l3.3-7.7h15.1l3.2,7.7h5.9l-14.1-32.2H296L281.9,97.7z M249.3,97.7h5.5V75l17.7,22.8h4.7
			v-32h-5.5v22.1l-17.1-22.1h-5.2V97.7z M222.3,81.6V70.8h8.2c4.2,0,6.6,1.9,6.6,5.3v0.1c0,3.2-2.6,5.4-6.6,5.4H222.3z M216.7,97.7
			h5.6V86.6h7l7.9,11.2h6.7l-8.6-12.1c4.4-1.3,7.6-4.4,7.6-9.7v-0.1c0-2.8-1-5.2-2.7-6.9c-2.1-2-5.2-3.2-9.2-3.2h-14.3V97.7z
			 M186.4,97.7h24v-5H192v-8.6H208v-5H192v-8.3h18.1v-5h-23.7V97.7z M165.7,97.7h5.7V70.9h10.2v-5.2h-26v5.2h10.2V97.7z M122.8,97.7
			h5.5V75L146,97.7h4.7v-32h-5.5v22.1L128,65.7h-5.2V97.7z M109.2,97.7h5.6v-32h-5.6V97.7z"
                />
                <path
                    class="st0"
                    d="M460.4,57.1h28.7v-7.4h-20.5v-7.9h17.8v-7.4h-17.8v-7.6h20.2v-7.4h-28.4V57.1z M439.8,57.7
			c7.3,0,11.6-2.6,15.5-6.7l-5.3-5.3c-3,2.7-5.6,4.4-9.9,4.4c-6.5,0-10.9-5.4-10.9-11.8v-0.1c0-6.5,4.6-11.7,10.9-11.7
			c3.8,0,6.7,1.6,9.6,4.3l5.3-6.1c-3.5-3.4-7.8-5.8-14.9-5.8c-11.6,0-19.7,8.8-19.7,19.5v0.1C420.4,49.2,428.7,57.7,439.8,57.7
			 M387.4,57.1h28.7v-7.4h-20.5v-7.9h17.8v-7.4h-17.8v-7.6h20.2v-7.4h-28.4V57.1z M370.6,57.1h8.3V19.4h-8.3V57.1z M343.7,38.4V26.9
			h6.4c4.1,0,6.7,2,6.7,5.7v0.1c0,3.2-2.4,5.7-6.6,5.7H343.7z M335.4,57.1h8.3V45.8h6.3c8.5,0,15.2-4.5,15.2-13.2v-0.1
			c0-7.7-5.4-13-14.4-13h-15.4V57.1z M305.6,37.7V26.9h8.2c4,0,6.5,1.8,6.5,5.4v0.1c0,3.2-2.3,5.3-6.4,5.3H305.6z M297.3,57.1h8.3
			V45h6.5l8.1,12.1h9.7l-9.2-13.5c4.8-1.8,8.1-5.6,8.1-11.7v-0.1c0-3.6-1.1-6.5-3.2-8.6c-2.5-2.5-6.2-3.8-11-3.8h-17.2V57.1z
			 M261.6,57.1h28.7v-7.4h-20.5v-7.9h17.8v-7.4h-17.8v-7.6h20.2v-7.4h-28.4V57.1z M237.2,57.1h8.3V27h11.5v-7.6h-31.2V27h11.5V57.1z
			 M208.6,57.6c8.1,0,13.8-4.2,13.8-11.7v-0.1c0-6.6-4.3-9.3-12-11.3c-6.5-1.7-8.1-2.5-8.1-5v-0.1c0-1.8,1.7-3.3,4.8-3.3
			c3.2,0,6.5,1.4,9.8,3.7l4.3-6.2c-3.8-3.1-8.5-4.8-14-4.8c-7.7,0-13.2,4.5-13.2,11.4v0.1c0,7.5,4.9,9.6,12.5,11.5
			c6.3,1.6,7.6,2.7,7.6,4.8v0.1c0,2.2-2,3.6-5.4,3.6c-4.3,0-7.9-1.8-11.3-4.6l-4.9,5.9C197.2,55.6,202.9,57.6,208.6,57.6
			 M165.3,41.3l5-12.2l5,12.2H165.3z M150.5,57.1h8.5l3.4-8.5h15.9l3.4,8.5h8.7l-16.2-38h-7.6L150.5,57.1z M108.3,57.1h8.1V32.6
			l10.5,15.9h0.2l10.6-16.1v24.6h8.2V19.4h-8.9l-9.9,15.9l-9.9-15.9h-8.9V57.1z"
                />
            </g>
            <g>
                <path class="st0" d="M4.8,4.5h79.6v108.4H4.8V4.5z M2.5,115.2h84.2V2.2H2.5V115.2z" />
                <path class="st0" d="M72.8,101.9H16.4V15.5h56.4V101.9z M74.3,14H14.9v89.4h59.4L74.8,14H74.3z" />
                <path class="st0" d="M68.9,98H20.4V19.4h48.5V98z M19.2,99.3h50.9V18.2H19.2V99.3z" />
                <g>
                    <path
                        class="st0"
                        d="M8.3,76.2c-0.4-1.3-0.8-2.6-1.1-4c-0.8-3.3-0.6-6,0.7-7.7c0.7-0.9,1.7-1.5,2.8-1.5h0c1.4,0,2,0.9,2.3,1.4
				c0.4,0.9,0.4,1.9-0.1,2.8c-0.6-0.5-0.9-0.6-1.4-0.6c-0.7,0-1.3,0.3-1.8,1.1c-0.8,1.3,0,3.4,0.9,5.9c0.8,2.1,1.6,4.4,1.7,6.8
				c0.1,5.6-1.3,10.1-2.5,13.7c0.2-3.3,0.4-7.5,0.1-10.9C9.7,80.9,9,78.7,8.3,76.2 M12,97.9c-0.6-0.1-1.2,0.1-1.5,0.7
				c-0.2,0.2-0.2,0.7-0.2,1c0.1,0.4,0.4,0.7,0.8,0.8c0.7,0.1,1.4-0.5,1.2-1.2c0-0.1-0.1-0.2-0.1-0.3c0.6,0.2,1,0.7,1,1.4
				c0,1.3-0.7,1.9-1.4,2.2c-0.8,0.3-1.6,0-2.1-0.8c-0.9-1.5-0.2-3.8,0.9-7.1c1.2-3.7,2.6-8.3,2.5-14.1c0-2.5-0.9-4.9-1.7-7.1
				c-0.8-2.1-1.5-4.1-1-5c0.3-0.5,0.7-0.7,1.1-0.7c0.5,0,0.9,0.4,1.1,0.5l0.4,0.4l0.4-0.4c0.8-1.2,1.1-2.7,0.4-4.1
				c-0.6-1.2-1.7-1.9-3.1-1.9h0c-1.3,0-2.6,0.7-3.5,1.8c-1,1.3-2,3.9-0.8,8.4c0.4,1.4,0.8,2.8,1.1,4C8.2,78.9,8.8,81,9,83.3
				c0.6,5.9-0.5,13.9-0.6,15.4c0,0.8-0.1,2.5,0.5,3.5c0.7,1.1,1.5,1.4,2.3,1.4c0.3,0,0.8-0.1,1-0.2c1.3-0.5,1.9-1.6,1.8-2.9
				C14,98.9,13.2,98,12,97.9"
                    />
                    <path
                        class="st0"
                        d="M79.5,83.2c-0.3,3.4-0.1,7.7,0.1,10.9c-1.1-3.6-2.5-8.1-2.5-13.7c0-2.3,0.9-4.7,1.7-6.8
				c0.9-2.4,1.7-4.5,0.9-5.9c-0.5-0.8-1.1-1.1-1.8-1.1c-0.5,0-0.8,0.2-1.4,0.6c-0.5-1-0.5-2-0.1-2.8c0.2-0.5,0.9-1.4,2.3-1.4h0
				c1.1,0,2.1,0.5,2.8,1.5c1.3,1.6,1.5,4.4,0.7,7.7c-0.4,1.4-0.8,2.7-1.1,4C80.4,78.7,79.7,80.9,79.5,83.2 M75.3,100.4
				c-0.1,1.3,0.6,2.4,1.8,2.9c0.3,0.1,0.7,0.2,1,0.2c0.8,0,1.5-0.3,2.3-1.4c0.7-1,0.5-2.7,0.5-3.5c0-1.5-1.2-9.5-0.6-15.4
				c0.2-2.2,0.8-4.3,1.6-6.8c0.4-1.3,0.8-2.6,1.1-4c1.2-4.6,0.2-7.1-0.8-8.4c-0.9-1.2-2.2-1.8-3.5-1.8h0c-1.4,0-2.5,0.7-3.1,1.9
				c-0.6,1.3-0.4,2.8,0.4,4.1l0.4,0.4l0.4-0.4c0.1-0.2,0.6-0.6,1.1-0.5c0.3,0,0.8,0.2,1.1,0.7c0.6,0.9-0.2,2.9-1,5
				c-0.8,2.1-1.7,4.6-1.7,7.1c-0.1,5.8,1.4,10.4,2.5,14.1c1,3.3,1.8,5.7,0.9,7.1c-0.5,0.8-1.3,1.1-2.1,0.8c-0.7-0.2-1.4-0.9-1.4-2.2
				c0-0.7,0.4-1.2,1-1.4c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.7,0.5,1.4,1.2,1.2c0.4-0.1,0.7-0.4,0.8-0.8c0.1-0.3,0-0.8-0.2-1
				c-0.3-0.6-0.9-0.8-1.5-0.7C76.2,98,75.4,98.9,75.3,100.4"
                    />
                    <path
                        class="st0"
                        d="M9.9,23.5c1.1,3.6,2.5,8.1,2.5,13.7c0,2.3-0.9,4.7-1.7,6.8c-0.9,2.4-1.7,4.5-0.9,5.9
				c0.5,0.8,1.1,1.1,1.8,1.1c0.5,0,0.8-0.1,1.4-0.6c0.5,0.9,0.5,2,0.1,2.8c-0.2,0.5-0.9,1.4-2.3,1.4h0c-1.1,0-2.1-0.5-2.8-1.5
				c-1.3-1.6-1.5-4.4-0.7-7.7c0.4-1.4,0.8-2.7,1.1-4c0.7-2.5,1.4-4.6,1.6-7C10.3,31.1,10.1,26.8,9.9,23.5 M11.2,17.2
				c-0.4,0.1-0.7,0.4-0.8,0.8c-0.1,0.3,0,0.8,0.2,1c0.3,0.6,0.9,0.8,1.5,0.7c1.1-0.1,2-1,2.1-2.5c0.1-1.3-0.6-2.4-1.8-2.9
				c-0.3-0.1-0.7-0.2-1-0.2c-0.8,0-1.5,0.3-2.3,1.4c-0.7,1-0.5,2.7-0.5,3.5c0,1.5,1.2,9.5,0.6,15.4c-0.2,2.2-0.8,4.3-1.6,6.8
				c-0.4,1.3-0.8,2.6-1.1,4c-1.2,4.6-0.2,7.1,0.8,8.4c0.9,1.2,2.2,1.8,3.5,1.8h0c1.4,0,2.5-0.7,3.1-1.9c0.6-1.3,0.4-2.8-0.4-4.1
				L13,49.1l-0.4,0.4c-0.1,0.2-0.6,0.6-1.1,0.5c-0.3,0-0.8-0.2-1.1-0.7c-0.6-0.9,0.2-2.9,1-5c0.8-2.1,1.7-4.6,1.7-7.1
				c0.1-5.8-1.4-10.4-2.5-14.1c-1-3.3-1.8-5.7-0.9-7.1c0.5-0.8,1.3-1.1,2.1-0.8c0.7,0.2,1.4,0.9,1.4,2.2c0,0.7-0.4,1.2-1,1.4
				c0.1-0.1,0.1-0.2,0.1-0.3C12.6,17.7,12,17.1,11.2,17.2"
                    />
                    <path
                        class="st0"
                        d="M81.7,53.1c-0.7,0.9-1.7,1.5-2.8,1.5h0c-1.4,0-2-0.9-2.3-1.4c-0.4-0.9-0.4-1.9,0.1-2.8
				c0.6,0.5,0.9,0.6,1.4,0.6c0.7,0,1.3-0.3,1.8-1.1c0.8-1.3,0-3.4-0.9-5.9c-0.8-2.1-1.6-4.4-1.7-6.8c-0.1-5.6,1.3-10.1,2.5-13.7
				c-0.2,3.3-0.4,7.5-0.1,10.9c0.2,2.3,0.9,4.5,1.6,7c0.4,1.3,0.8,2.6,1.1,4C83.2,48.7,82.9,51.5,81.7,53.1 M83.2,45.2
				c-0.4-1.4-0.8-2.8-1.1-4c-0.7-2.4-1.3-4.6-1.6-6.8C79.9,28.5,81,20.5,81,19c0-0.8,0.1-2.5-0.5-3.5c-0.7-1.1-1.5-1.4-2.3-1.4
				c-0.3,0-0.8,0.1-1,0.2c-1.3,0.5-1.9,1.6-1.8,2.9c0.1,1.5,0.9,2.3,2.1,2.5c0.6,0.1,1.2-0.1,1.5-0.7c0.2-0.2,0.2-0.7,0.2-1
				c-0.1-0.4-0.4-0.7-0.8-0.8c-0.7-0.1-1.4,0.5-1.2,1.2c0,0.1,0.1,0.2,0.1,0.3c-0.6-0.2-1-0.7-1-1.4c0-1.3,0.7-1.9,1.4-2.2
				c0.8-0.3,1.6,0,2.1,0.8c0.9,1.5,0.2,3.8-0.9,7.1c-1.2,3.7-2.6,8.3-2.5,14.1c0,2.5,0.9,4.9,1.7,7.1c0.8,2.1,1.5,4.1,1,5
				c-0.3,0.5-0.7,0.7-1.1,0.7c-0.5,0-0.9-0.4-1.1-0.5l-0.4-0.4l-0.4,0.4c-0.8,1.2-1.1,2.7-0.4,4.1c0.6,1.2,1.7,1.9,3.1,1.9h0
				c1.3,0,2.6-0.7,3.5-1.8C83.4,52.3,84.4,49.8,83.2,45.2"
                    />
                    <path
                        class="st0"
                        d="M79.4,61.1c-1.3-0.1-2.1-0.8-2.1-0.8s1.7,0.2,2.5,0.2c0.8-0.1,1.8-0.5,1.8-1.8c0-1.1-0.6-1.6-1.8-1.8
				c-0.5-0.1-2.4,0.1-2.4,0.1c0,0,0.9-0.6,1.7-0.7c2.1-0.2,3.5,0.3,3.5,2.4C82.5,60.4,81.4,61.3,79.4,61.1"
                    />
                    <path class="st0" d="M43.6,107.6c0-0.6,0.5-1,1-1s1,0.5,1,1c0,0.6-0.5,1-1,1S43.6,108.1,43.6,107.6" />
                    <path
                        class="st0"
                        d="M44.6,111.4c-1.3,0-2.2-0.5-2.7-1.4c-0.6-1.1-0.3-2.5,0.3-3.3c0.3-0.3,0.9-0.6,0.9-0.6s-0.4,0.6-0.5,1.1
				c-0.3,1.1-0.3,1.7,0.1,2.5c0.3,0.6,0.9,0.9,1.8,0.9c0.9,0,1.5-0.3,1.8-0.9c0.4-0.8,0.4-1.8,0.1-2.6c-0.1-0.2-0.6-1-0.6-1
				s0.8,0.3,1,0.6c0.7,1,0.9,2.3,0.3,3.4C46.9,110.7,46.2,111.4,44.6,111.4"
                    />
                    <path
                        class="st0"
                        d="M8.8,107.6c0-0.6,0.5-1,1-1c0.6,0,1,0.5,1,1c0,0.6-0.5,1-1,1C9.3,108.6,8.8,108.1,8.8,107.6"
                    />
                    <path
                        class="st0"
                        d="M40.5,108.1c0,0.7-0.4,1.4-1,1.9c-0.7,0.5-1.6,0.8-2.8,0.8c-0.8,0-1.6-0.1-2.5-0.3c-1-0.3-1.9-0.5-2.8-0.8
				c-1.8-0.5-3.3-1-4.9-1.1c-0.8-0.1-1.8-0.1-2.9-0.1c-1.2,0-2.4,0-3.8,0.1c2.3-0.7,5-1.4,8.3-1.4c0.1,0,0.2,0,0.3,0
				c1.6,0,3.3,0.6,4.7,1.2l0,0c1.2,0.5,2.3,0.9,3.2,0.9c0.4,0,0.7-0.1,1-0.2c0.7-0.4,0.8-1,0.8-1.4c0-0.3-0.1-0.6-0.4-0.9
				c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2C39.9,106.8,40.5,107.2,40.5,108.1L40.5,108.1z M36.7,111.5
				C36.7,111.5,36.7,111.5,36.7,111.5C36.7,111.5,36.8,111.5,36.7,111.5C36.8,111.5,36.8,111.5,36.7,111.5 M36.7,110.9
				C36.6,110.9,36.6,110.9,36.7,110.9C36.6,110.9,36.6,110.9,36.7,110.9 M35.9,110.9C35.9,110.9,35.9,110.9,35.9,110.9
				C35.9,110.9,35.9,110.9,35.9,110.9L35.9,110.9 M35.5,110.8C35.5,110.8,35.4,110.8,35.5,110.8C35.4,110.8,35.4,110.8,35.5,110.8
				C35.4,110.8,35.5,110.8,35.5,110.8L35.5,110.8 M35.1,110.8c0,0-0.1,0-0.1,0l0,0C35,110.7,35.1,110.7,35.1,110.8L35.1,110.8
				 M34.7,110.7C34.7,110.7,34.7,110.7,34.7,110.7C34.7,110.7,34.7,110.7,34.7,110.7z M26.5,109.3c-0.2,0-0.4,0-0.6-0.1
				C26.1,109.2,26.3,109.2,26.5,109.3c0.2,0,0.4,0,0.6,0.1C26.9,109.3,26.7,109.3,26.5,109.3 M24.5,108.5c-0.1,0-0.1,0-0.2,0h0
				C24.4,108.5,24.4,108.5,24.5,108.5L24.5,108.5 M23.7,108.5C23.7,108.5,23.7,108.5,23.7,108.5C23.7,108.5,23.7,108.5,23.7,108.5z
				 M18.6,108.1C18.6,108.1,18.6,108.1,18.6,108.1C18.6,108.1,18.6,108.1,18.6,108.1c0.2-0.1,0.3-0.1,0.5-0.2
				C18.9,108,18.7,108.1,18.6,108.1 M28.1,106.4c0.1,0,0.2,0,0.3,0c-0.2,0-0.4,0-0.5,0C28,106.4,28.1,106.4,28.1,106.4 M33.5,108.3
				C33.5,108.3,33.5,108.3,33.5,108.3c-0.1,0-0.2-0.1-0.3-0.1C33.3,108.2,33.4,108.2,33.5,108.3 M34.2,108.5c0.1,0,0.1,0,0.2,0.1
				c0,0,0,0,0,0C34.3,108.6,34.2,108.5,34.2,108.5C34.2,108.5,34.2,108.5,34.2,108.5 M34.5,108.6c0.1,0,0.2,0.1,0.3,0.1h0
				C34.6,108.7,34.5,108.6,34.5,108.6L34.5,108.6 M34.7,108.7c0,0,0.1,0,0.1,0C34.8,108.7,34.8,108.7,34.7,108.7
				C34.7,108.7,34.7,108.7,34.7,108.7 M35.6,108.9c0.1,0,0.1,0,0.2,0l0,0C35.7,108.9,35.6,108.9,35.6,108.9L35.6,108.9z M35.8,109
				C35.8,109,35.8,109,35.8,109c0.1,0,0.2,0,0.2,0C36,109,35.9,109,35.8,109C35.8,109,35.8,109,35.8,109z M36.1,109c0,0,0.1,0,0.1,0
				C36.2,109,36.1,109,36.1,109z M36.9,108.2c0,0,0.1-0.1,0.1-0.1C37,108.2,37,108.2,36.9,108.2C36.9,108.2,36.9,108.2,36.9,108.2
				C36.9,108.2,36.9,108.2,36.9,108.2 M38.8,105.7C38.8,105.7,38.8,105.7,38.8,105.7C38.8,105.7,38.8,105.7,38.8,105.7
				C38.8,105.7,38.8,105.7,38.8,105.7 M15.1,109.7C15.1,109.7,15.1,109.7,15.1,109.7C15.1,109.7,15.1,109.7,15.1,109.7
				C15.1,109.7,15.1,109.7,15.1,109.7 M40,105.8c-0.4-0.2-0.7-0.3-1.1-0.3c-0.6,0-1.2,0.2-1.8,0.6l0,0l0,0l-0.3,0.2l-0.1,0.1
				l0.1,0.1l0.3,0.2c0.1,0.1,0.4,0.4,0.3,0.6c0,0.2-0.1,0.5-0.5,0.7c-0.1,0.1-0.3,0.1-0.5,0.1c-0.7,0-1.7-0.4-2.7-0.8l-0.2-0.1
				c-1.5-0.6-3.2-1.2-5-1.2c-0.1,0-0.2,0-0.3,0c-3.9,0-7.1,1-9.6,1.8c-1.6,0.5-2.8,0.9-3.8,0.9c-0.4,0-0.8-0.1-1.1-0.3
				c-0.3-0.2-0.5-0.5-0.5-0.9c0-0.4,0.1-1.4,1.3-1.5c0.3,0,0.5,0.1,0.7,0.3c0,0,0,0,0,0c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1
				c0.4,0,0.7-0.2,0.9-0.5c0.2-0.2,0.3-0.6,0.3-0.9c0-0.9-0.9-1.5-2-1.6c-0.9,0-1.4,0.4-1.8,1.1c-0.3,0.6-0.7,2.4-2.3,3.2
				c-0.8,0.4-1.7,0.3-2.4-0.2c-0.7-0.5-0.9-1.4-0.8-2.1c0.1-1.3,0.8-2.2,1.6-2.6c0.5-0.2,1.4-0.5,1.4-0.5s-0.5-0.2-1.1-0.1
				c-1.6,0.3-2.6,1.7-2.7,3.3c-0.1,0.8,0.1,2,1.1,2.7c0.6,0.4,1.2,0.6,1.9,0.6c0.5,0,0.9-0.1,1.4-0.3c0.8-0.4,1.5-1.1,1.9-1.8
				c0.1,0.2,0.3,0.4,0.6,0.5c0.4,0.3,1,0.4,1.9,0.4c0.2,0,0.3,0,0.5,0c0,0,0.1,0,0.1,0c0.2,0,0.7-0.1,1.4-0.1
				c1.5-0.2,4.1-0.4,6.6-0.4c1,0,2,0,2.8,0.1c1.5,0.2,3,0.6,4.7,1.1c0.9,0.3,1.8,0.5,2.8,0.8c1,0.3,1.9,0.4,2.7,0.4
				c1.3,0,2.5-0.3,3.3-1c0.8-0.6,1.3-1.6,1.3-2.6v0C41.4,107.1,40.9,106.3,40,105.8"
                    />
                    <path
                        class="st0"
                        d="M80.7,107.6c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1C80.3,108.6,80.7,108.1,80.7,107.6"
                    />
                    <path
                        class="st0"
                        d="M80.7,58.7c0-0.6-0.5-1-1-1c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1C80.3,59.7,80.7,59.3,80.7,58.7"
                    />
                    <path
                        class="st0"
                        d="M10.2,56.3c1.3,0.1,2.1,0.8,2.1,0.8s-1.7-0.2-2.5-0.2C9,56.9,8,57.4,8,58.7c0,1.1,0.6,1.6,1.8,1.8
				c0.5,0.1,2.4-0.1,2.4-0.1c0,0-0.9,0.6-1.7,0.7c-2.1,0.2-3.5-0.3-3.5-2.4C7.1,57.1,8.2,56.1,10.2,56.3"
                    />
                    <path
                        class="st0"
                        d="M8.8,58.7c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1s-0.5-1-1-1C9.3,57.7,8.8,58.1,8.8,58.7"
                    />
                    <path
                        class="st0"
                        d="M74.1,109.7C74.1,109.7,74.1,109.7,74.1,109.7C74.2,109.7,74.2,109.7,74.1,109.7
				C74.1,109.7,74.1,109.7,74.1,109.7"
                    />
                    <path
                        class="st0"
                        d="M50.5,105.7C50.4,105.7,50.4,105.7,50.5,105.7C50.4,105.7,50.4,105.7,50.5,105.7
				C50.4,105.7,50.4,105.7,50.5,105.7"
                    />
                    <path
                        class="st0"
                        d="M52.3,108.2C52.3,108.2,52.3,108.2,52.3,108.2c0,0-0.1-0.1-0.1-0.1C52.2,108.2,52.3,108.2,52.3,108.2
				C52.3,108.2,52.3,108.2,52.3,108.2"
                    />
                    <path class="st0" d="M53.2,109c0,0-0.1,0-0.1,0C53.1,109,53.1,109,53.2,109z" />
                    <path
                        class="st0"
                        d="M53.4,109C53.4,109,53.4,109,53.4,109c-0.1,0-0.2,0-0.2,0C53.3,109,53.3,109,53.4,109
				C53.4,109,53.4,109,53.4,109z"
                    />
                    <path
                        class="st0"
                        d="M53.7,108.9c-0.1,0-0.1,0-0.2,0l0,0C53.5,108.9,53.6,108.9,53.7,108.9L53.7,108.9"
                    />
                    <path
                        class="st0"
                        d="M54.5,108.7c0,0-0.1,0-0.1,0C54.4,108.7,54.5,108.7,54.5,108.7C54.5,108.7,54.5,108.7,54.5,108.7"
                    />
                    <path
                        class="st0"
                        d="M54.8,108.6c-0.1,0-0.2,0.1-0.3,0.1l0,0C54.6,108.7,54.7,108.6,54.8,108.6C54.8,108.6,54.8,108.6,54.8,108.6
				"
                    />
                    <path
                        class="st0"
                        d="M55.1,108.5c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0C54.9,108.6,55,108.5,55.1,108.5L55.1,108.5"
                    />
                    <path class="st0" d="M56,108.2c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0C55.9,108.2,56,108.2,56,108.2" />
                    <path
                        class="st0"
                        d="M61.3,106.4c-0.2,0-0.4,0-0.5,0c0.1,0,0.2,0,0.3,0C61.2,106.4,61.2,106.4,61.3,106.4"
                    />
                    <path
                        class="st0"
                        d="M70.2,108c0.2,0.1,0.3,0.1,0.5,0.2c0,0,0,0,0,0c0,0,0,0,0,0C70.5,108.1,70.3,108,70.2,108"
                    />
                    <polyline class="st0" points="65.6,108.5 65.6,108.5 65.6,108.5 			" />
                    <path class="st0" d="M64.7,108.5c0.1,0,0.1,0,0.2,0h0C64.9,108.5,64.8,108.5,64.7,108.5L64.7,108.5" />
                    <path
                        class="st0"
                        d="M62.2,109.3c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.6-0.1c-0.2,0-0.4,0-0.6,0.1
				C62.6,109.3,62.4,109.3,62.2,109.3"
                    />
                    <path
                        class="st0"
                        d="M54.5,110.7C54.5,110.7,54.5,110.7,54.5,110.7C54.5,110.7,54.5,110.7,54.5,110.7z"
                    />
                    <path
                        class="st0"
                        d="M54.1,110.8c0,0,0.1,0,0.1,0l0,0C54.2,110.7,54.2,110.7,54.1,110.8C54.1,110.8,54.1,110.8,54.1,110.8"
                    />
                    <path
                        class="st0"
                        d="M53.7,110.8C53.8,110.8,53.8,110.8,53.7,110.8C53.8,110.8,53.8,110.8,53.7,110.8
				C53.8,110.8,53.8,110.8,53.7,110.8L53.7,110.8"
                    />
                    <path
                        class="st0"
                        d="M53.3,110.9C53.3,110.9,53.4,110.9,53.3,110.9L53.3,110.9C53.4,110.9,53.3,110.9,53.3,110.9L53.3,110.9"
                    />
                    <path
                        class="st0"
                        d="M52.6,110.9C52.6,110.9,52.6,110.9,52.6,110.9C52.6,110.9,52.6,110.9,52.6,110.9"
                    />
                    <path
                        class="st0"
                        d="M52.4,111.5C52.5,111.5,52.5,111.5,52.4,111.5C52.5,111.5,52.5,111.5,52.4,111.5
				C52.5,111.5,52.5,111.5,52.4,111.5"
                    />
                    <path
                        class="st0"
                        d="M65.6,108.4c-1.1,0-2.1,0-2.9,0.1c-1.6,0.2-3.2,0.6-4.9,1.1c-0.9,0.3-1.8,0.5-2.8,0.8
				c-0.9,0.2-1.7,0.3-2.5,0.3c-1.2,0-2.1-0.3-2.8-0.8c-0.6-0.5-1-1.2-1-1.9v0c0-0.9,0.6-1.3,0.9-1.5c0.3-0.1,0.6-0.2,0.9-0.2
				c0.3,0,0.6,0.1,0.9,0.2c-0.3,0.3-0.4,0.6-0.4,0.9c0,0.4,0.1,0.9,0.8,1.4c0.3,0.2,0.6,0.2,1,0.2c0.9,0,1.9-0.4,3.2-0.9l0,0
				c1.4-0.5,3.1-1.1,4.7-1.2c0.1,0,0.2,0,0.3,0c3.3,0,6,0.7,8.3,1.4C68,108.4,66.7,108.4,65.6,108.4 M83,107.7
				c-0.1-1.7-1.1-3-2.7-3.3c-0.6-0.1-1.1,0.1-1.1,0.1s0.8,0.2,1.4,0.5c0.8,0.4,1.5,1.4,1.6,2.6c0.1,0.7-0.1,1.6-0.8,2.1
				c-0.6,0.5-1.5,0.6-2.4,0.2c-1.6-0.8-2-2.7-2.3-3.2c-0.4-0.6-0.9-1.1-1.8-1.1c-1.1,0-2,0.7-2,1.6c0,0.4,0.1,0.7,0.3,0.9
				c0.2,0.3,0.5,0.5,0.9,0.5c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1c0,0,0,0,0,0c0.2-0.2,0.4-0.3,0.7-0.3c1.2,0.1,1.4,1.1,1.3,1.5
				c0,0.4-0.2,0.7-0.5,0.9c-0.3,0.2-0.7,0.3-1.1,0.3c-1,0-2.2-0.4-3.8-0.9c-2.5-0.8-5.7-1.8-9.6-1.8c-0.1,0-0.2,0-0.3,0
				c-1.8,0-3.5,0.7-5,1.2l-0.2,0.1c-1,0.4-2,0.8-2.7,0.8c-0.2,0-0.4,0-0.5-0.1c-0.4-0.2-0.4-0.5-0.5-0.7c0-0.3,0.2-0.5,0.3-0.6
				l0.3-0.2l0.1-0.1l-0.1-0.1l-0.3-0.2l0,0l0,0c-0.6-0.4-1.2-0.6-1.8-0.6c-0.4,0-0.8,0.1-1.1,0.3c-0.9,0.4-1.4,1.3-1.4,2.3v0
				c0,1,0.5,1.9,1.3,2.6c0.8,0.7,2,1,3.3,1c0.8,0,1.7-0.1,2.7-0.4c1-0.3,1.9-0.5,2.8-0.8c1.8-0.5,3.2-0.9,4.7-1.1
				c0.8-0.1,1.7-0.1,2.8-0.1c2.5,0,5.1,0.3,6.6,0.4c0.7,0.1,1.2,0.1,1.4,0.1c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.5,0
				c0.9,0,1.5-0.1,1.9-0.4c0.3-0.2,0.5-0.4,0.6-0.5c0.4,0.7,1.1,1.4,1.9,1.8c0.5,0.2,0.9,0.3,1.4,0.3c0.6,0,1.3-0.2,1.9-0.6
				C82.9,109.7,83,108.5,83,107.7"
                    />
                    <path
                        class="st0"
                        d="M43.8,9.9c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1C44.3,8.8,43.8,9.3,43.8,9.9"
                    />
                    <path
                        class="st0"
                        d="M44.9,6.1c-1.3,0-2.2,0.5-2.7,1.4c-0.6,1.1-0.3,2.5,0.3,3.3c0.3,0.3,0.9,0.6,0.9,0.6s-0.4-0.6-0.5-1.1
				c-0.3-1.1-0.3-1.7,0.1-2.5C43.3,7.3,44,7,44.9,7c0.9,0,1.5,0.3,1.8,0.9c0.4,0.8,0.4,1.8,0.1,2.6c-0.1,0.2-0.6,1-0.6,1
				s0.8-0.3,1-0.6c0.7-1,0.9-2.3,0.3-3.4C47.2,6.8,46.4,6.1,44.9,6.1"
                    />
                    <path
                        class="st0"
                        d="M8.8,9.9c0,0.6,0.5,1,1,1c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1C9.3,8.8,8.8,9.3,8.8,9.9"
                    />
                    <path
                        class="st0"
                        d="M15.4,7.8C15.3,7.8,15.3,7.8,15.4,7.8C15.3,7.8,15.3,7.8,15.4,7.8C15.3,7.8,15.4,7.8,15.4,7.8"
                    />
                    <path
                        class="st0"
                        d="M39,11.7C39,11.7,39,11.8,39,11.7C39.1,11.8,39,11.8,39,11.7C39,11.8,39,11.7,39,11.7"
                    />
                    <path
                        class="st0"
                        d="M37.2,9.2C37.2,9.2,37.2,9.2,37.2,9.2c0,0,0.1,0.1,0.1,0.1C37.3,9.3,37.2,9.2,37.2,9.2
				C37.2,9.2,37.2,9.2,37.2,9.2"
                    />
                    <path class="st0" d="M36.3,8.4c0,0,0.1,0,0.1,0C36.4,8.4,36.3,8.4,36.3,8.4z" />
                    <path
                        class="st0"
                        d="M36.1,8.5C36.1,8.5,36.1,8.5,36.1,8.5c0.1,0,0.2,0,0.2,0C36.2,8.5,36.2,8.5,36.1,8.5
				C36.1,8.5,36.1,8.5,36.1,8.5z"
                    />
                    <path class="st0" d="M35.8,8.5c0.1,0,0.1,0,0.2,0C35.9,8.5,35.9,8.5,35.8,8.5L35.8,8.5" />
                    <path class="st0" d="M35,8.7c0,0,0.1,0,0.1,0C35.1,8.7,35,8.7,35,8.7C35,8.7,35,8.7,35,8.7" />
                    <path
                        class="st0"
                        d="M34.7,8.8c0.1,0,0.2-0.1,0.3-0.1l0,0C34.9,8.8,34.8,8.8,34.7,8.8C34.7,8.8,34.7,8.8,34.7,8.8"
                    />
                    <path
                        class="st0"
                        d="M34.4,8.9c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0,0C34.5,8.9,34.5,8.9,34.4,8.9L34.4,8.9"
                    />
                    <path class="st0" d="M33.4,9.3c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0C33.6,9.2,33.5,9.3,33.4,9.3" />
                    <path
                        class="st0"
                        d="M28.2,11.1c0.2,0,0.4,0,0.5,0c-0.1,0-0.2,0-0.3,0C28.3,11.1,28.2,11.1,28.2,11.1"
                    />
                    <path
                        class="st0"
                        d="M19.3,9.5c-0.2-0.1-0.3-0.1-0.5-0.2c0,0,0,0,0,0c0,0,0,0,0,0C19,9.4,19.1,9.4,19.3,9.5"
                    />
                    <path class="st0" d="M23.9,8.9C23.9,8.9,23.9,8.9,23.9,8.9C23.9,8.9,23.9,8.9,23.9,8.9z" />
                    <path class="st0" d="M24.7,8.9c-0.1,0-0.1,0-0.2,0h0C24.6,8.9,24.7,8.9,24.7,8.9L24.7,8.9" />
                    <path
                        class="st0"
                        d="M27.3,8.1c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.6,0.1c0.2,0,0.4,0,0.6-0.1C26.9,8.2,27.1,8.1,27.3,8.1"
                    />
                    <path class="st0" d="M35,6.8C35,6.8,35,6.8,35,6.8C35,6.8,35,6.8,35,6.8z" />
                    <path
                        class="st0"
                        d="M35.4,6.7c0,0-0.1,0-0.1,0l0,0C35.3,6.7,35.3,6.7,35.4,6.7C35.4,6.7,35.4,6.7,35.4,6.7"
                    />
                    <path
                        class="st0"
                        d="M35.7,6.6C35.7,6.6,35.7,6.6,35.7,6.6C35.6,6.7,35.6,6.6,35.7,6.6C35.7,6.6,35.7,6.6,35.7,6.6z"
                    />
                    <path
                        class="st0"
                        d="M36.2,6.6C36.1,6.6,36.1,6.6,36.2,6.6L36.2,6.6C36.1,6.6,36.1,6.6,36.2,6.6L36.2,6.6"
                    />
                    <path class="st0" d="M36.9,6.5C36.9,6.5,36.9,6.5,36.9,6.5C36.9,6.5,36.9,6.5,36.9,6.5z" />
                    <path class="st0" d="M37,5.9C37,5.9,37,5.9,37,5.9C37,5.9,37,5.9,37,5.9C37,5.9,37,5.9,37,5.9" />
                    <path
                        class="st0"
                        d="M40.7,9.3c0,0.9-0.6,1.3-0.9,1.5C39.5,11,39.2,11,38.9,11c-0.3,0-0.6-0.1-0.9-0.2c0.3-0.3,0.4-0.6,0.4-0.9
				c0-0.4-0.1-0.9-0.8-1.4c-0.3-0.2-0.6-0.2-1-0.2c-0.9,0-1.9,0.4-3.2,0.9l0,0c-1.4,0.5-3.1,1.1-4.7,1.2c-0.1,0-0.2,0-0.3,0
				c-3.3,0-6-0.7-8.3-1.4C21.5,9,22.7,9,23.9,9c1.1,0,2.1,0,2.9-0.1c1.6-0.2,3.2-0.6,4.9-1.1c0.9-0.3,1.8-0.5,2.8-0.8
				c0.9-0.2,1.7-0.3,2.5-0.3c1.2,0,2.1,0.3,2.8,0.8C40.4,7.9,40.7,8.6,40.7,9.3L40.7,9.3z M40.3,6.8c-0.8-0.7-2-1-3.3-1
				c-0.8,0-1.7,0.1-2.7,0.4c-1,0.3-1.9,0.5-2.8,0.8c-1.8,0.5-3.2,0.9-4.7,1.1c-0.8,0.1-1.7,0.1-2.8,0.1c-2.5,0-5.1-0.3-6.6-0.4
				c-0.7-0.1-1.2-0.1-1.4-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.5,0c-0.9,0-1.5,0.1-1.9,0.4c-0.3,0.2-0.5,0.4-0.6,0.5
				c-0.4-0.7-1.1-1.4-1.9-1.8C10.4,6.5,10,6.4,9.5,6.4c-0.6,0-1.3,0.2-1.9,0.6C6.6,7.8,6.4,9,6.5,9.8c0.1,1.7,1.1,3,2.7,3.3
				c0.6,0.1,1.1-0.1,1.1-0.1S9.5,12.8,9,12.6c-0.8-0.4-1.5-1.4-1.6-2.6C7.3,9.2,7.4,8.3,8.1,7.8c0.6-0.5,1.5-0.6,2.4-0.2
				c1.6,0.8,2,2.7,2.3,3.2c0.4,0.6,0.9,1.1,1.8,1.1c1.1,0,2-0.7,2-1.6c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.3-0.5-0.5-0.9-0.5
				c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1c0,0,0,0,0,0c-0.2,0.2-0.4,0.3-0.7,0.3c-1.2-0.1-1.4-1.1-1.3-1.5c0-0.4,0.2-0.7,0.5-0.9
				c0.3-0.2,0.7-0.3,1.1-0.3c1,0,2.2,0.4,3.8,0.9c2.5,0.8,5.7,1.8,9.6,1.8c0.1,0,0.2,0,0.3,0c1.8,0,3.5-0.7,5-1.2l0.2-0.1
				c1-0.4,2-0.8,2.7-0.8c0.2,0,0.4,0,0.5,0.1c0.4,0.2,0.4,0.5,0.5,0.7c0,0.3-0.2,0.5-0.3,0.6l-0.3,0.2l-0.1,0.1l0.1,0.1l0.3,0.2l0,0
				l0,0c0.6,0.4,1.2,0.6,1.8,0.6c0.4,0,0.8-0.1,1.1-0.3c0.9-0.4,1.4-1.3,1.4-2.3v0C41.6,8.3,41.1,7.4,40.3,6.8"
                    />
                    <path
                        class="st0"
                        d="M80.7,9.9c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1C80.3,8.8,80.7,9.3,80.7,9.9"
                    />
                    <path
                        class="st0"
                        d="M74.4,7.8C74.4,7.8,74.4,7.8,74.4,7.8C74.4,7.8,74.4,7.8,74.4,7.8C74.4,7.8,74.4,7.8,74.4,7.8"
                    />
                    <path
                        class="st0"
                        d="M50.7,11.8C50.7,11.8,50.7,11.8,50.7,11.8C50.7,11.8,50.7,11.7,50.7,11.8C50.7,11.7,50.7,11.8,50.7,11.8"
                    />
                    <path
                        class="st0"
                        d="M52.5,9.2c0,0-0.1,0.1-0.1,0.1C52.4,9.3,52.5,9.2,52.5,9.2C52.5,9.2,52.5,9.2,52.5,9.2
				C52.5,9.2,52.5,9.2,52.5,9.2"
                    />
                    <path class="st0" d="M53.4,8.4c0,0-0.1,0-0.1,0C53.3,8.4,53.4,8.4,53.4,8.4z" />
                    <path
                        class="st0"
                        d="M53.6,8.5C53.6,8.5,53.6,8.5,53.6,8.5c-0.1,0-0.2,0-0.2,0C53.5,8.5,53.5,8.5,53.6,8.5
				C53.6,8.5,53.6,8.5,53.6,8.5z"
                    />
                    <path class="st0" d="M53.9,8.5c-0.1,0-0.1,0-0.2,0C53.8,8.5,53.8,8.5,53.9,8.5z" />
                    <path
                        class="st0"
                        d="M54.7,8.7c0,0-0.1,0-0.1,0C54.6,8.7,54.7,8.7,54.7,8.7C54.7,8.7,54.7,8.7,54.7,8.7"
                    />
                    <path class="st0" d="M55,8.8c-0.1,0-0.2-0.1-0.3-0.1l0,0C54.8,8.8,54.9,8.8,55,8.8L55,8.8" />
                    <path class="st0" d="M55.3,8.9c-0.1,0-0.1,0-0.2-0.1h0C55.2,8.9,55.2,8.9,55.3,8.9L55.3,8.9" />
                    <path class="st0" d="M56,9.2C56,9.2,56,9.2,56,9.2c0.1,0,0.2,0.1,0.3,0.1C56.2,9.3,56.1,9.2,56,9.2" />
                    <path
                        class="st0"
                        d="M61.3,11.1c-0.1,0-0.2,0-0.3,0c0.2,0,0.4,0,0.5,0C61.5,11.1,61.4,11.1,61.3,11.1"
                    />
                    <path
                        class="st0"
                        d="M70.9,9.3C70.9,9.3,70.9,9.3,70.9,9.3C70.9,9.3,70.9,9.3,70.9,9.3c-0.2,0.1-0.3,0.1-0.5,0.2
				C70.6,9.4,70.7,9.4,70.9,9.3"
                    />
                    <polyline class="st0" points="65.8,8.9 65.8,8.9 65.8,8.9 			" />
                    <path class="st0" d="M65,8.9c0.1,0,0.1,0,0.2,0h0C65.1,8.9,65,8.9,65,8.9L65,8.9" />
                    <path
                        class="st0"
                        d="M63,8.2c0.2,0,0.4,0,0.6,0.1C63.4,8.2,63.2,8.2,63,8.2c-0.2,0-0.4,0-0.6-0.1C62.6,8.1,62.8,8.2,63,8.2"
                    />
                    <path class="st0" d="M54.7,6.8C54.7,6.8,54.7,6.8,54.7,6.8C54.7,6.8,54.7,6.8,54.7,6.8z" />
                    <path
                        class="st0"
                        d="M54.3,6.7c0,0,0.1,0,0.1,0h0C54.4,6.7,54.4,6.7,54.3,6.7C54.3,6.7,54.3,6.7,54.3,6.7"
                    />
                    <path class="st0" d="M54,6.6C54,6.6,54,6.6,54,6.6C54.1,6.6,54.1,6.6,54,6.6C54,6.6,54,6.6,54,6.6z" />
                    <path
                        class="st0"
                        d="M53.5,6.6C53.6,6.6,53.6,6.6,53.5,6.6L53.5,6.6C53.6,6.6,53.6,6.6,53.5,6.6L53.5,6.6"
                    />
                    <path class="st0" d="M52.8,6.5C52.8,6.5,52.8,6.5,52.8,6.5C52.8,6.5,52.8,6.5,52.8,6.5" />
                    <path
                        class="st0"
                        d="M52.7,5.9C52.7,5.9,52.7,5.9,52.7,5.9C52.7,5.9,52.7,5.9,52.7,5.9C52.7,5.9,52.7,5.9,52.7,5.9"
                    />
                    <path
                        class="st0"
                        d="M61.3,10.3c-0.1,0-0.2,0-0.3,0c-1.6,0-3.3-0.6-4.7-1.2l0,0C55,8.7,54,8.3,53.1,8.3c-0.4,0-0.7,0.1-1,0.2
				c-0.7,0.4-0.8,1-0.8,1.4c0,0.3,0.1,0.6,0.4,0.9C51.4,11,51.1,11,50.8,11c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.2-0.9-0.6-0.9-1.5v0
				c0-0.7,0.4-1.4,1-1.9c0.7-0.5,1.6-0.8,2.8-0.8c0.8,0,1.6,0.1,2.5,0.3c1,0.3,1.9,0.5,2.8,0.8c1.8,0.5,3.3,1,4.9,1.1
				C63.8,9,64.7,9,65.8,9c1.2,0,2.4,0,3.8-0.1C67.4,9.6,64.6,10.3,61.3,10.3 M82.1,7.1c-0.6-0.4-1.2-0.6-1.9-0.6
				c-0.5,0-0.9,0.1-1.4,0.3c-0.8,0.4-1.5,1.1-1.9,1.8c-0.1-0.2-0.3-0.4-0.6-0.5c-0.4-0.3-1-0.4-1.9-0.4c-0.2,0-0.3,0-0.5,0
				c0,0-0.1,0-0.1,0c-0.2,0-0.7,0.1-1.4,0.1c-1.5,0.2-4.1,0.4-6.6,0.4c-1,0-2,0-2.8-0.1c-1.5-0.2-3-0.6-4.7-1.1
				c-0.9-0.3-1.8-0.5-2.8-0.8c-1-0.3-1.9-0.4-2.7-0.4c-1.3,0-2.5,0.3-3.3,1c-0.8,0.6-1.3,1.6-1.3,2.6v0c0,1,0.5,1.9,1.4,2.3
				c0.4,0.2,0.7,0.3,1.2,0.3c0.6,0,1.2-0.2,1.8-0.6l0,0l0,0l0.3-0.2l0.1-0.1l-0.1-0.1l-0.3-0.2c-0.1-0.1-0.4-0.4-0.3-0.6
				c0-0.2,0.1-0.5,0.5-0.7c0.1-0.1,0.3-0.1,0.5-0.1c0.7,0,1.7,0.4,2.7,0.8L56,10c1.5,0.6,3.2,1.2,5,1.2c0.1,0,0.2,0,0.3,0
				c3.9,0,7.1-1,9.6-1.8c1.6-0.5,2.8-0.9,3.8-0.9c0.4,0,0.8,0.1,1.1,0.3c0.3,0.2,0.5,0.5,0.5,0.9c0,0.4-0.1,1.4-1.3,1.5
				c-0.3,0-0.5-0.1-0.7-0.3c0,0,0,0,0,0c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.2-0.3,0.6-0.3,0.9
				c0,0.9,0.9,1.5,2,1.6c0.9,0,1.4-0.4,1.8-1.1c0.3-0.6,0.7-2.4,2.3-3.2c0.8-0.4,1.7-0.3,2.4,0.2c0.7,0.5,0.9,1.4,0.8,2.1
				c-0.1,1.3-0.8,2.2-1.6,2.6c-0.5,0.3-1.4,0.5-1.4,0.5s0.5,0.2,1.1,0.1c1.6-0.3,2.6-1.7,2.7-3.3C83.3,9,83.1,7.8,82.1,7.1"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<style scoped type="text/css">
.st0 {
    fill: #20419a;
}
</style>
